<template>
    <div>
        <div>
          <v-row justify="center" no-gutters class="mt-4 mb-8">
            <v-col cols="12" class="justify-center">
              <v-row no-gutters>
                <v-col class="px-2">
                  <v-progress-linear color="primary" :value="progress" rounded height="8"></v-progress-linear>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-col cols="12" v-show="step === 1">
            <div class="primary--text text-subtitle-2 font-weight-600">Full Name</div>
            <v-text-field v-model="full_name" dense outlined hide-details flat type="text" placeholder="Full name"></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 2">
            <div class="primary--text text-subtitle-2 font-weight-600">Kennel Name (if applicable)</div>
            <v-text-field v-model="kennel_name" dense outlined hide-details flat type="text" placeholder="Kennel Name"></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 3">
            <div class="primary--text text-subtitle-2 font-weight-600">Website:</div>
            <v-text-field v-model="website" dense outlined hide-details flat type="text" placeholder="http://www.example.com"></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 4">
            <div class="primary--text text-subtitle-2 font-weight-600">Social Media Links:</div>
            <v-textarea v-model="social_media_links" dense outlined hide-details flat :placeholder="social_media_placeholder"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 5">
            <div class="primary--text text-subtitle-2 font-weight-600">Years of Breeding Experience:</div>
            <v-text-field v-model="years_of_experience" dense outlined hide-details flat type="number" placeholder="0"></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 6">
            <div class="primary--text text-subtitle-2 font-weight-600">Breeds You Specialize In:</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="Afador, Bassador, Beabull, etc..."
                v-model="specialized_breeds"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 7">
            <div class="primary--text text-subtitle-2 font-weight-600">Certifications or Memberships:</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="e.g., Kennel Club, Breed-Specific Organizations"
                v-model="certifications"></v-textarea>
        </v-col>

        <v-col cols="12" v-show="step === 8">
            <div class="primary--text text-subtitle-2 font-weight-600">Describe your approach to breeding and your goals for your breeding program:</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="breeding_philosophy"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 9">
            <div class="primary--text text-subtitle-2 font-weight-600">What health tests do you perform on your breeding animals?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="Please provide specific tests and expected results."
                v-model="health_testing_protocols"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 10">
            <div class="primary--text text-subtitle-2 font-weight-600">Average Litter Size:</div>
            <v-text-field v-model="average_litter_size" dense outlined hide-details flat type="number" placeholder="0"></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 11">
            <div class="primary--text text-subtitle-2 font-weight-600">How often do you breed your females?</div>
            <v-text-field v-model="breeding_frequency" dense outlined hide-details flat type="text" placeholder=""></v-text-field>
        </v-col>
        <v-col cols="12" v-show="step === 12">
            <div class="primary--text text-subtitle-2 font-weight-600">Describe how you socialize your puppies/kittens during their first weeks.</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="puppy_socialization"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 13">
            <div class="primary--text text-subtitle-2 font-weight-600">What veterinary care do you provide for your breeding animals and puppies/kittens?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="veterinary_care"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 14">
            <div class="primary--text text-subtitle-2 font-weight-600">Describe the living conditions and housing for your breeding animals.</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="living_conditions"></v-textarea>
        </v-col>

        <v-col cols="12" v-show="step === 15">
            <div class="primary--text text-subtitle-2 font-weight-600">How do you ensure the physical and mental well-being of your breeding animals?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="animal_welfare_commitment"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 16">
            <div class="primary--text text-subtitle-2 font-weight-600">What ethical considerations do you take into account in your breeding practices?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="ethical_considerations"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 17">
            <div class="primary--text text-subtitle-2 font-weight-600">Do you require buyers to spay/neuter their pets?</div>
            <v-radio-group v-model="requires_spay_neuter" class="mt-0" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" v-show="step === 18">
            <div class="primary--text text-subtitle-2 font-weight-600">What is your policy if a buyer can no longer care for the pet?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="rehoming_policy"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 19">
            <div class="primary--text text-subtitle-2 font-weight-600">Do you offer any health guarantees or contracts to buyers?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="guarantee_contract"></v-textarea>
        </v-col>

        <v-col cols="12" v-show="step === 20">
            <div class="primary--text text-subtitle-2 font-weight-600">How do you determine the price of your puppies/kittens?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="pricing_details"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 21">
            <div class="primary--text text-subtitle-2 font-weight-600">Do you have a waiting list for your puppies/kittens?</div>
            <v-radio-group v-model="has_waiting_list" class="mt-0" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" v-show="step === 22">
            <div class="primary--text text-subtitle-2 font-weight-600">How do you screen potential buyers to ensure they are suitable homes for your animals?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="screening_process"></v-textarea>
        </v-col>
        <v-col cols="12" v-show="step === 23">
            <div class="primary--text text-subtitle-2 font-weight-600">What kind of support do you offer to buyers after they take home a puppy/kitten?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="post_sale_support"></v-textarea>
        </v-col>

        <v-col cols="12" v-show="step === 24">
            <div class="primary--text text-subtitle-2 font-weight-600">Do you participate in any dog shows or competitions? (If applicable)</div>
            <v-radio-group v-model="participates_in_shows" class="mt-0" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" v-show="step === 25">
            <div class="primary--text text-subtitle-2 font-weight-600">Are you willing to provide photos or videos of your kennel and breeding animals?</div>
            <v-radio-group v-model="willing_to_provide_media" class="mt-0" row hide-details>
                <v-radio label="Yes" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" v-show="step === 26">
            <div class="primary--text text-subtitle-2 font-weight-600">Is there any additional information you would like to share about your breeding program?</div>
            <v-textarea class="mt-1" dense outlined hide-details auto-grow placeholder="" v-model="additional_information"></v-textarea>
        </v-col>

        <v-card-actions class="mt-4">

          <v-btn v-if="showPrevButton" color="gray" depressed @click="prevStep">Back</v-btn>
          <v-spacer></v-spacer>

          <v-btn v-if="!isLastStep" color="primary" @click="nextStep" :disabled="!isStepValid">Next</v-btn>
          <v-btn v-if="isLastStep" color="primary" @click="submit(1)" :disabled="!isStepValid">Submit</v-btn>

        </v-card-actions>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'BreederQuestionnaire',

  computed: {
    showPrevButton() {
      return this.stepsArray.indexOf(this.step) > 0;
    },
    isLastStep() {
      return this.stepsArray.indexOf(this.step) === this.stepsArray.length - 1;
    },
    progress() {
      // Calculate progress as a percentage
      let currentIndex = this.stepsArray.indexOf(this.step);
      return ((currentIndex + 1) / this.stepsArray.length) * 100;
    },
    isStepValid() {
      // Check if the current step's fields are valid
      switch (this.step) {
        case 1:
          return this.full_name.trim() !== '';
        case 2:
          return this.kennel_name.trim() !== '';
        case 3:
          return this.website.trim() !== '';
        case 4:
          return this.social_media_links.trim() !== '';
        case 5:
          return this.years_of_experience !== '';
        case 6:
          return this.specialized_breeds.trim() !== '';
        case 7:
          return this.certifications.trim() !== '';
        case 8:
          return this.breeding_philosophy.trim() !== '';
        case 9:
          return this.health_testing_protocols.trim() !== '';
        case 10:
          return this.average_litter_size !== '';
        case 11:
          return this.breeding_frequency.trim() !== '';
        case 12:
          return this.puppy_socialization.trim() !== '';
        case 13:
          return this.veterinary_care.trim() !== '';
        case 14:
          return this.living_conditions.trim() !== '';
        case 15:
          return this.animal_welfare_commitment.trim() !== '';
        case 16:
          return this.ethical_considerations.trim() !== '';
        case 17:
          return this.requires_spay_neuter !== '';
        case 18:
          return this.rehoming_policy.trim() !== '';
        case 19:
          return this.guarantee_contract.trim() !== '';
        case 20:
          return this.pricing_details.trim() !== '';
        case 21:
          return this.has_waiting_list !== '';
        case 22:
          return this.screening_process.trim() !== '';
        case 23:
          return this.post_sale_support.trim() !== '';
        case 24:
          return this.participates_in_shows !== '';
        case 25:
          return this.willing_to_provide_media !== '';
        case 26:
          return this.additional_information.trim() !== '';
        default:
          return false;
      }
    },
  },

    mounted() {


        if (this.$store.getters.userDetails.category !== 'breeder') {
            this.$router.replace('home');
        }
        else {
            this.getBreederQuestionnaire();
        }

    },

    data() {
        return {
          step: 1,
          stepsArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,17,18,19,20,21,22,23,24,25,26],

          social_media_placeholder : `facebook:
instagram:
twitter:`,

            full_name: '',
            kennel_name: '',
            website: '',
            social_media_links: '',
            years_of_experience: '',
            specialized_breeds: '',
            certifications: '',
            breeding_philosophy: '',
            health_testing_protocols: '',
            average_litter_size: '',
            breeding_frequency: '',
            puppy_socialization: '',
            veterinary_care: '',
            living_conditions: '',
            animal_welfare_commitment: '',
            ethical_considerations: '',
            requires_spay_neuter: '',
            rehoming_policy: '',
            guarantee_contract: '',
            pricing_details: '',
            has_waiting_list: '',
            screening_process: '',
            post_sale_support: '',
            participates_in_shows: '',
            willing_to_provide_media: '',
            additional_information: '',

            submitting: false,
            dataLoading: false,
            snackbar: false,
            snackbar_text: '',
        }
    },

    methods: {
      validateStep() {
        this.isStepValid;
      },
      closeDialog() {
        this.$emit('close-dialog');
      },
      nextStep() {
        let currentIndex = this.stepsArray.indexOf(this.step);
        if (currentIndex !== -1 && currentIndex < this.stepsArray.length - 1) {
          this.submit(0);
          this.step = this.stepsArray[currentIndex + 1];
        }
      },
      prevStep() {
        let currentIndex = this.stepsArray.indexOf(this.step);
        if (currentIndex > 0) {
          this.step = this.stepsArray[currentIndex - 1];
        }
      },

      getBreederQuestionnaire() {
            this.dataLoading = true;
            this.$store.dispatch('getBreederQuestionnaire').then((resp) => {

                this.dataLoading = false;

                this.full_name = resp.data.full_name == null ? '' : resp.data.full_name;
                this.kennel_name = resp.data.kennel_name == null ? '' : resp.data.kennel_name;
                this.website = resp.data.website == null ? '' : resp.data.website;
                this.social_media_links = resp.data.social_media_links == null ? '' : resp.data.social_media_links;
                this.years_of_experience = resp.data.years_of_experience == null ? '' : resp.data.years_of_experience;
                this.specialized_breeds = resp.data.specialized_breeds == null ? '' : resp.data.specialized_breeds;
                this.certifications = resp.data.certifications == null ? '' : resp.data.certifications;
                this.breeding_philosophy = resp.data.breeding_philosophy == null ? '' : resp.data.breeding_philosophy;
                this.health_testing_protocols = resp.data.health_testing_protocols == null ? '' : resp.data.health_testing_protocols;
                this.average_litter_size = resp.data.average_litter_size == null ? '' : resp.data.average_litter_size;
                this.breeding_frequency = resp.data.breeding_frequency == null ? '' : resp.data.breeding_frequency;
                this.puppy_socialization = resp.data.puppy_socialization == null ? '' : resp.data.puppy_socialization;
                this.veterinary_care = resp.data.veterinary_care == null ? '' : resp.data.veterinary_care;
                this.living_conditions = resp.data.living_conditions == null ? '' : resp.data.living_conditions;
                this.animal_welfare_commitment = resp.data.animal_welfare_commitment == null ? '' : resp.data.animal_welfare_commitment;
                this.ethical_considerations = resp.data.ethical_considerations == null ? '' : resp.data.ethical_considerations;
                this.requires_spay_neuter = resp.data.requires_spay_neuter == null ? '' : resp.data.requires_spay_neuter;
                this.rehoming_policy = resp.data.rehoming_policy == null ? '' : resp.data.rehoming_policy;
                this.guarantee_contract = resp.data.guarantee_contract == null ? '' : resp.data.guarantee_contract;
                this.pricing_details = resp.data.pricing_details == null ? '' : resp.data.pricing_details;
                this.has_waiting_list = resp.data.has_waiting_list == null ? '' : resp.data.has_waiting_list;
                this.screening_process = resp.data.screening_process == null ? '' : resp.data.screening_process;
                this.post_sale_support = resp.data.post_sale_support == null ? '' : resp.data.post_sale_support;
                this.participates_in_shows = resp.data.participates_in_shows == null ? '' : resp.data.participates_in_shows;
                this.willing_to_provide_media = resp.data.willing_to_provide_media == null ? '' : resp.data.willing_to_provide_media;
                this.additional_information = resp.data.additional_information == null ? '' : resp.data.additional_information;
            })
                .catch(err => {
                    this.dataLoading = false;
                    this.snackbar_text = "Oops, Unable to get the details";
                    this.snackbar = true;
                });

        },

        submit(final) {
            this.submitting = true;

            let formData = new FormData();
            formData.append('full_name', this.full_name);
            formData.append('kennel_name', this.kennel_name);
            formData.append('website', this.website);
            formData.append('social_media_links', this.social_media_links);
            formData.append('years_of_experience', this.years_of_experience);
            formData.append('specialized_breeds', this.specialized_breeds);
            formData.append('certifications', this.certifications);
            formData.append('breeding_philosophy', this.breeding_philosophy);
            formData.append('health_testing_protocols', this.health_testing_protocols);
            formData.append('average_litter_size', this.average_litter_size);
            formData.append('breeding_frequency', this.breeding_frequency);
            formData.append('puppy_socialization', this.puppy_socialization);
            formData.append('veterinary_care', this.veterinary_care);
            formData.append('living_conditions', this.living_conditions);
            formData.append('animal_welfare_commitment', this.animal_welfare_commitment);
            formData.append('ethical_considerations', this.ethical_considerations);
            formData.append('requires_spay_neuter', this.requires_spay_neuter);
            formData.append('rehoming_policy', this.rehoming_policy);
            formData.append('guarantee_contract', this.guarantee_contract);
            formData.append('pricing_details', this.pricing_details);
            formData.append('has_waiting_list', this.has_waiting_list);
            formData.append('screening_process', this.screening_process);
            formData.append('post_sale_support', this.post_sale_support);
            formData.append('participates_in_shows', this.participates_in_shows);
            formData.append('willing_to_provide_media', this.willing_to_provide_media);
            formData.append('additional_information', this.additional_information);
            formData.append('step', this.step);

            this.$store.dispatch('submitBreederQuestionnaire', {
              formData
            })
            .then((resp) => {
              this.submitting = false;
              if(final) {
                this.closeDialog();
              }
            })
            .catch(err => {
              this.submitting = false;
              this.snackbar_text = "Oops, there is an error while saving. Please retry or contact support";
              this.snackbar = true;
            });
        }
    }
}

</script>
