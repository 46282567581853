<template>
    <div>
        <v-dialog v-model="this.$store.getters.messageCreditPop" persistent scrollable
            :fullscreen="$vuetify.breakpoint.xsOnly" max-width="450">

            <v-card>

                <v-toolbar flat dark color="primary" max-height="56">

                    <v-btn icon @click.native="close">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="text-subtitle-2">Message Credits</v-toolbar-title>


                </v-toolbar>


                <v-card-text class="px-0 pb-4">


                    <div class="ma-6 text-center">
                        <p class="text-subtitl-2 font-weight-medium primary--text mb-0">You have
                            <strong>{{ this.$store.getters.userDetails.mcredits }}</strong> Message credit<span v-show="this.$store.getters.userDetails.mcredits != 1">s</span>!
                        </p>
                    </div>

                    <div class="pa-4 text-center pt-2">
                        <p>Use message credits to <span class="yellow">send messages</span> and <span class="yellow">unlock received messages</span>.</p>
                    </div>



                    <v-list two-line>


                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="primary" dark>
                                    mdi-instagram
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">Follow us on Instagram</v-list-item-title>

                                <v-list-item-subtitle class="text-caption">+2 message credit <span class="grey--text"
                                        v-show="this.$store.getters.userDetails.instagram_followed && !this.$store.getters.userDetails.instagram_follow_verified">(pending
                                        verification)</span></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <div
                                    v-if="this.$store.getters.userDetails.instagram_followed">
                                    <v-icon color="success">mdi-check-circle</v-icon>
                                </div>
                                <v-btn small color="primary" v-else @click="instagramFollowDialog = true">
                                    Follow
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>


                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="primary" dark>
                                    mdi-twitter
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">Follow us on Twitter</v-list-item-title>
                                <v-list-item-subtitle class="text-caption">+2 message credit <span class="grey--text"
                                        v-show="this.$store.getters.userDetails.twitter_followed && !this.$store.getters.userDetails.twitter_follow_verified">(pending
                                        verification)</span></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <div
                                    v-if="this.$store.getters.userDetails.twitter_followed">
                                    <v-icon color="success">mdi-check-circle</v-icon>
                                </div>
                                <v-btn small color="primary" v-else @click="twitterFollowDialog = true">
                                    Follow
                                </v-btn>

                            </v-list-item-action>
                        </v-list-item>


                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="primary" dark>
                                    mdi-cellphone-text
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">Use our mobile app</v-list-item-title>
                                <v-list-item-subtitle class="text-caption">+2 message credit </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <div
                                    v-if="this.$store.getters.userDetails.app_installed">
                                    <v-icon color="success">mdi-check-circle</v-icon>
                                </div>
                                <v-btn small color="primary" v-else @click="installApp">
                                    Install
                                </v-btn>

                            </v-list-item-action>
                        </v-list-item>

                    </v-list>




                    <v-card class="ma-4 mild-shadow">


                        <v-toolbar flat color="primary" dense>
                            <v-toolbar-title class="text-caption white--text font-weight-medium">My Message Credits
                                Usage</v-toolbar-title>

                        </v-toolbar>

                        <v-card-text>
                            <v-row no-gutters v-if="this.$store.getters.userDetails.message_credits.length == 0">
                                <v-col cols="12" class="pa-6 text-center">
                                    <v-icon size="80" color="#eee">mdi-email-fast-outline</v-icon>
                                    <div>No Message Credits Usage</div>
                                </v-col>
                            </v-row>

                            <v-row class="px-4" v-for="(item, index) in this.$store.getters.userDetails.message_credits"
                                :key="index" no-gutters align="center" style="border-bottom:1px solid #eee">
                                <v-col cols="3">
                                    <v-icon x-small color="green" v-if="item.action == 'Credit'">
                                        mdi-plus
                                    </v-icon>
                                    <v-icon x-small color="red lighten-1" v-else>
                                        mdi-minus
                                    </v-icon>
                                    {{ Math.abs(item.credits) }}
                                </v-col>
                                <v-col cols="9" class="text-caption py-2">
                                    <div>{{ item.created_at || new Date() | moment("D MMM, h:mm a") }}</div>
                                    {{ item.note }}
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>


                </v-card-text>


            </v-card>

        </v-dialog>

        <v-dialog v-model="instagramFollowDialog" max-width="400">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <div class="text-overline font-weight-bold">Step 1</div>
                    <div class="text-center">
                        <v-btn color="primary" @click="hasInstagramFollowed = true" target="_blank" href="https://swiy.io/HLlJ" style="letter-spacing:0.3px">Follow us on Instagram</v-btn> 
                    </div>
                    <div class="text-overline pt-4 font-weight-bold">Step 2</div>
                    <div class="text-caption secondary--text">Please follow us on instagram before entering your Instagram handle</div>
                    <div class="text-caption pt-4">Enter your instagram handle</div>
                    <div>
                        <v-text-field class="pt-2" outlined dense v-model="instagram_handle" :disabled="!hasInstagramFollowed"
                            placeholder="your instagram handle" prefix="@"></v-text-field>
                    </div>
                    <div class="text-caption">
                        <span><strong>Note:</strong> Once we verify the details, the message credit will be added to your
                            account.</span>
                    </div>


                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="instagramFollowDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submitFollow('instagram')" :loading="submitting"
                        :disabled="submitting || !hasInstagramFollowed">Submit</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>


        <v-dialog v-model="twitterFollowDialog" max-width="400">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <div class="text-overline font-weight-bold">Step 1</div>
                    <div class="text-center">
                        <v-btn color="primary" @click="hasTwitterFollowed = true" target="_blank" href="https://swiy.io/HLlT" style="letter-spacing:0.3px">Follow us on Twitter</v-btn>
                    </div>
                    <div class="text-overline pt-4 font-weight-bold">Step 2</div>
                    <div class="text-caption secondary--text">Please follow us on Twitter before entering your Twitter handle</div>
                    <div class="text-caption pt-4">Enter your twitter handle</div>
                    <div>
                        <v-text-field class="pt-2" outlined dense v-model="twitter_handle" :disabled="!hasTwitterFollowed" placeholder="your twitter handle"
                            prefix="@"></v-text-field>
                    </div>
                    <div class="text-caption pt-2">
                        <span><strong>Note:</strong> Once we verify the details, the message credit will be added to your
                            account.</span>
                    </div>


                </v-card-text>

                <v-card-actions>
                    <v-btn text color="grey" @click="twitterFollowDialog = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="submitFollow('twitter')" :loading="submitting"
                        :disabled="submitting || !hasTwitterFollowed">Submit</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>



        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
    
<script>
import axios from "axios";
import Bowser from 'bowser';
export default {
    name: 'MessageCredit',

    mounted() {

    },

    data() {
        return {

            processing: false,
            submitting: false,
            snackbar: false,
            snackbar_text: '',
            instagramFollowDialog: false,
            twitterFollowDialog: false,
            instagram_handle: this.$store.getters.userDetails.instagram_handle,
            twitter_handle: this.$store.getters.userDetails.twitter_handle,
            hasInstagramFollowed: false,
            hasTwitterFollowed: false,


        }
    },

    methods: {
        close() {
            this.$store.dispatch('showMessageCreditPop', false);
        },
        showPremium() {
            this.close();
            this.$store.dispatch('showPremiumPop', true);
        },

        submitFollow(platform) {
            this.submitting = true;

            if (platform == 'instagram') {
                if (this.instagram_handle == '' || this.instagram_handle == null) {
                    this.submitting = false;
                    this.snackbar_text = "Instagram handle is required";
                    this.snackbar = true;
                }
                else {
                    let handle = this.instagram_handle

                    this.$store.dispatch('submitFollow', {
                        platform, handle
                    })
                        .then((resp) => {
                            this.$store.dispatch('profile');
                            this.instagramFollowDialog = false;
                            this.submitting = false;
                            this.snackbar_text = "Followed on Instagram";
                            this.snackbar = true;
                        })
                        .catch(err => {
                            this.submitting = false;
                            this.snackbar_text = "Error submitting";
                            this.snackbar = true;
                        });
                }
            }

            if (platform == 'twitter') {
                if (this.twitter_handle == '' || this.twitter_handle == null) {
                    this.submitting = false;
                    this.snackbar_text = "Twitter handle is required";
                    this.snackbar = true;
                }
                else {
                    let handle = this.twitter_handle

                    this.$store.dispatch('submitFollow', {
                        platform, handle
                    })
                        .then((resp) => {
                            this.$store.dispatch('profile');
                            this.twitterFollowDialog = false;
                            this.submitting = false;
                            this.snackbar_text = "Followed on Twitter";
                            this.snackbar = true;
                        })
                        .catch(err => {
                            this.submitting = false;
                            this.snackbar_text = "Error submitting";
                            this.snackbar = true;
                        });
                }
            }

        },

        installApp()
        {
            let deviceType = '';

            const browser = Bowser.getParser(window.navigator.userAgent);
            const osName = browser.getOS().name.toLowerCase();

            if (osName === 'ios' || osName === 'macos') {
            deviceType = 'Apple';
            } else if (osName === 'android') {
            deviceType = 'Android';
            } else {
            deviceType = 'Other';
            }

            
            if(deviceType == 'Apple')
            {
                window.open("https://apps.apple.com/app/apple-store/id6478047015?pt=118572154&ct=Web%20App%20Referral&mt=8");
            }
            else if(deviceType == 'Android')
            {
                this.snackbar_text = "Our Android app will be launched soon";
                this.snackbar = true;
            }
            else
            {
                this.snackbar_text = "Oops!! We dont have an app for your device. You may use the browser version.";
                this.snackbar = true;
            }

        },


        checkOut() {

            var s =
            {
                //Reset the cart session  to remove everything added to the cart previously.
                'reset': true,
                "paymentContact": {
                    "email": this.$store.getters.userDetails.email,
                    "country": this.$store.getters.userDetails.country || 'US'
                },
                //Define the product path(s) and the quantity to add.
                'products': [
                    {
                        'path': 'message-credits',
                        'quantity': 1
                    }
                ],
                'checkout': true,
                "tags": {
                    "customer": this.$store.getters.userDetails.xid
                },
            }

            /* window.gtag('event', 'purchase_init', {
                event_category: 'Purchase',
                event_label: 'Direct Connect',
            }); */

            window.fastspring.builder.push(s);


        },





    }
}
</script>
    